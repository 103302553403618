import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import Headroom from 'headroom.js';
import Flickity from 'flickity';
import Instafeed from 'instafeed.js';

const html = document.querySelector('html');
const body = document.querySelector('body');


function headroom() {

	const header = document.querySelector('header');
	const sideSelectMenu = document.getElementById('side-select-menu');

	const hr  = new Headroom(header, {
		tolerance: 0,
		offset: 30,
		classes: {
			pinned : 'pinned',
			unpinned : 'hide'
		}
	});
	hr.init();


	// HEADROOM.JS FOR SIDE NAVIGATION MENUS
	if ((sideSelectMenu) && (body.offsetWidth < 768)) {

		const ms  = new Headroom(sideSelectMenu, {
			tolerance: 0,
			offset: 30,
			classes: {
				pinned : 'pinned',
				unpinned : 'hide'
			}
		});
		ms.init();

	}

}


// HOME PAGE CAROUSEL
function homePageCarousel() {

	const home = document.getElementById('home');

	if (!home) return;

	const heroCarousel = document.querySelector('.hero-carousel');
	const cell = document.querySelectorAll('.cell');
	const cellCount = cell.length;

	const settings = {

		initialIndex: Math.floor(Math.random() * cellCount),
		setGallerySize: false,
		cellAlign: 'left',
		contain: true,
		freeScroll: false,
		wrapAround: true,
		prevNextButtons: true,
		pageDots: false,
		watchCSS: false,
		lazyLoad: true,
		imagesLoaded: true

	}

	const flkty = new Flickity(heroCarousel, settings);

}


// INSTAGRAM FEED
function instafeed() {

	//developers.facebook.com/docs/instagram-basic-display-api/getting-started
	//developers.facebook.com/docs/instagram-basic-display-api/guides/long-lived-access-tokens

	const carouselContainer = document.getElementById('instafeed');
	const instagramCarousel = document.querySelector('.instagram-carousel');

	if (!carouselContainer) return;

	function instaCarousel() {

		new Flickity(instagramCarousel, {

			initialIndex: 1,
			setGallerySize: false,
			cellAlign: 'center',
			contain: true,
			freeScroll: false,
			wrapAround: true,
			prevNextButtons: false,
			pageDots: false,
			watchCSS: true

		});

	}

	const feed = new Instafeed({

		accessToken: 'IGQVJXcmhYZAFBxb251dDdlRnNyUUFncFo0aTFNc3hDSjZAJV2hob0Q1MVlCMk4zUnNRLUNES1JQUS1wQ3J0bVJScENnMkRFc09nRW1oX2RFNUxzREpwSEVIamFEM3VrYW04UjFQeE5B',
		limit: 9,
		template: '<div class="ig-item"><a href="{{link}}" target="_blank"><div class="overlay insta"></div><figure><img src="" data-src="{{image}}" alt="{{caption}}" class="object-fit img-blur-up lazyload"></figure></a></div>',
		after : instaCarousel

	});
	feed.run();

}


// PREVENT SCROLLING ON TOUCH DEVICES WHEN OVERLAY ACTIVE
function preventTouchmove() {

	if (html.classList.contains('nav-open')) {

		html.ontouchmove = function(e) { e.preventDefault(); };

	} else {

		html.ontouchmove = function() { return true; };

	}

}


// MAILCHIMP SUBSCRIBE FORM
function mailchimpForm() {

	"use strict";

	// Insert the Mailchimp Subscribe Form...
	var overlayModal = document.querySelector('.overlay.modal');
	var modalContent = overlayModal.querySelector('.modal-content');

	var contents = '<h3>Join The Newsletter</h3>' +
						'<form action="https://thepalomar.us3.list-manage.com/subscribe/post?u=08884d45263922504cfd96571&amp;id=aed339ec8c" method="post" id="subscribe" class="validate" target="_blank" novalidate>' +
						'<fieldset>' +
						'<p>By joining you are signing up to receive sporadic news and details of upcoming special events from <strong>Jacob the Angel</strong> and occasionally the other members of our family - <a href="https://thepalomar.co.uk" target="_blank">The Palomar</a>, <a href="https://thepalomar.co.uk" target="_blank">The Barbary</a> and <a href="https://thepalomar.co.uk" target="_blank">The Blue Posts</a>.</p>' +
						'<input type="text" value="" name="FNAME" class="text" id="mce-FNAME" placeholder="Your Name">' +
						'<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Your Email *">' +
						'<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">' +
						'<div id="mce-responses" class="clear">' +
						'<div class="response" id="mce-error-response" style="display:none"></div>' +
						'<div class="response" id="mce-success-response" style="display:none"></div>' +
						'</div>' +
						'<div style="position: absolute; left: -5000px;" aria-hidden="true">' +
						'<input type="text" name="b_08884d45263922504cfd96571_aed339ec8c" tabindex="-1" value="">' +
						'</div>' +
						'</fieldset>' +
						'</form>';

	modalContent.innerHTML += contents;

	var subscribeForm = document.getElementById('subscribe');
	var emailInput = subscribeForm.querySelector('input#mce-EMAIL');
	var formSubmit = subscribeForm.querySelector('input#mc-embedded-subscribe');

	// Confirm Email input...
	emailInput.addEventListener('input', function() {

		var input = this;
		var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		var isEmail = re.test(input.value);

		if(isEmail) {

			subscribeForm.classList.remove('invalid');
			subscribeForm.classList.add('valid');

		} else {

			subscribeForm.classList.remove('valid');
			subscribeForm.classList.add('invalid');

		}

	}, false);

	// Confirm everything is Valid when submitting...
	formSubmit.addEventListener('click', function(e) {

		var errorFree = true;

		if (!(subscribeForm.classList.contains('valid'))) {

			var errorFree = false;

		}

		if (!errorFree) {

			e.preventDefault();

		} else {

			setTimeout(deactivateModal, 1000);

		}

	}, false);

}


// CREDITS MODAL CONTENTS
function credits() {

	'use strict';

	var overlayModal = document.querySelector('.overlay.modal');
	var modalContent = overlayModal.querySelector('.modal-content');

	var contents = '<h3>Credits</h3>' +
						'<ul class="credits">' +
						'<li><p>Identity<br/><a href="https://heredesign.co.uk" title="Here Design" target="_blank" rel="noopener">Here Design</a></p></li>' +
						'<li><p>Photography<br/><a href="http://jasonlowe.eu" title="Jason Lowe" target="_blank" rel="noopener">Jason Lowe</a></p></li>' +
						'<li><p>Website<br/><a href="https://richkelly.uk" title="Rich Kelly" target="_blank" rel="noopener">Rich Kelly</a></p></li>' +
						'</ul>';

	modalContent.innerHTML += contents;

}


// MOBILE NAVIGATION OPEN/CLOSE
function mobileNavigationToggle(e) {

	if (!e.target.closest('.nav-control')) return;

	e.preventDefault();

	html.classList.toggle('nav-open');

}


// PRIMARY NAVIGATION LINKS
function primaryNavigationLinks(e) {

	if (!e.target.matches('#nav-panel li a')) return;

	e.preventDefault();

	switch (true) {

		case html.classList.contains('nav-open'):

			html.classList.remove('nav-open');

			setTimeout(function() {

				window.location = e.target.href;

			}, 500);

		break;

		default:

			window.location = e.target.href;

		break;

	}

}


// DEACTIVATE AND REMOVE MODAL
function deactivateModal(e){

	const modalActive = html.classList.contains('modal-active');

	if ((e.keyCode === 27 && modalActive) || (e.target.closest('button.close') && modalActive)) {

		const modalOverlay = body.querySelector('.modal.overlay');

		html.classList.remove('modal-active');

		setTimeout(function() {

			body.removeChild(modalOverlay);

		}, 600);

		preventTouchmove();

	}

}


// ACTIVATE AND CREATE MODAL
function activateModal(e) {

	if (!e.target.matches('a.modal')) return;

	const clicked = e.target;

	const overlayDiv = document.createElement('div');
	overlayDiv.classList.add('overlay');
	overlayDiv.classList.add('modal');
	body.appendChild(overlayDiv);

	const buttonClose = document.createElement('button');
	buttonClose.classList.add('close');
	overlayDiv.appendChild(buttonClose);

	const spanOne = document.createElement('span');
	spanOne.classList.add('one');
	buttonClose.appendChild(spanOne);

	const spanTwo = document.createElement('span');
	spanTwo.classList.add('two');
	buttonClose.appendChild(spanTwo);

	const modalContent = document.createElement('div');
	modalContent.classList.add('modal-content');
	overlayDiv.appendChild(modalContent);

	setTimeout(function() {

		html.classList.add('modal-active');

		if (clicked.classList.contains('subscribe')) {

			mailchimpForm();

		}

		if (clicked.classList.contains('credits')) {

			credits();

		}

		preventTouchmove();

	}, 500);

	buttonClose.addEventListener('click', deactivateModal, false);

}


function pageLoadState() {

	html.classList.add('page-loaded');

}


document.addEventListener('click', function(e) {

	mobileNavigationToggle(e);

	primaryNavigationLinks(e);

	activateModal(e);

}, false);

document.addEventListener('keyup', function(e) {

	deactivateModal(e);

}, false);

window.addEventListener('DOMContentLoaded', headroom, false);
window.addEventListener('DOMContentLoaded', homePageCarousel, false);
window.addEventListener('DOMContentLoaded', instafeed, false);

window.addEventListener('load', pageLoadState, false);
